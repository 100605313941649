<template>
  <div :class="wrapperClass">
    <label v-if="label" :for="id" class="de-form-input-text-label">{{ label }}</label>

    <prime-input-text
      :id="id"
      ref="input"
      v-model="value"
      :autofocus="autofocus"
      v-bind="$attrs"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :minlength="minlength"
      class="de-form-input-text"
      :class="{ 'is-error': !!errorMessage || isError }"
      v-on="validationListeners"
    />

    <div v-if="errorMessage" class="de-form-error">
      {{ errorMessage }}
    </div>

    <div
      v-if="hintMessage || isWordLimitShown"
      class="tw-flex tw-items-center tw-justify-between tw-text-300 tw-leading-400 tw-text-primary-300 tw-mt-1"
    >
      <p v-if="hintMessage">{{ hintMessage }}</p>

      <p v-if="isWordLimitShown">
        <span :class="wordLimitClass">
          {{ value.length }}
        </span>

        <span>/{{ maxlength || minlength }}</span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed, nextTick, onMounted, ref } from 'vue';
import { PrimeInputText } from '#components';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  id: string;
  label?: string;
  hintMessage?: string;
  isError?: boolean;
  wrapperClass?: string;
  maxlength?: number;
  minlength?: number;
  showWordLimit?: boolean;
  placeholder?: string;
  autofocus?: boolean;
}>();

// The `id` is returned in a function because we want to make sure it stays reactive
// If the id changes you want `useField` to be able to pick it up
const { errorMessage, value, handleChange, handleBlur } = useField(() => props.id, undefined, {
  validateOnValueUpdate: false,
});

const validationListeners = {
  blur: (evt: Event) => handleBlur(evt, true),
  change: handleChange,
  input: (evt: Event) => handleChange(evt, !!errorMessage.value),
};

const isWordLimitShown = computed(() => {
  return props.showWordLimit && (props.maxlength || props.minlength) && value.value;
});
const wordLimitClass = computed(() => {
  if (
    (props.maxlength && value.value?.length <= props.maxlength) ||
    (props.minlength && value.value?.length >= props.minlength)
  ) {
    return 'tw-text-success-500';
  }

  return 'tw-text-accent-500';
});

const input = ref<InstanceType<typeof PrimeInputText> | null>(null);
async function focus() {
  await nextTick();
  input.value?.$el?.focus();
}

onMounted(() => {
  if (props.autofocus) focus();
});

defineExpose({ focus });
</script>
